const defaultApiParams = (rootGetters, namespace = `alphixCollection`) => {
    return {
        country: rootGetters[`${namespace}/getCollectionSetting`].country || [],
        domainId: rootGetters[`${namespace}/getCollectionSetting`].domain || [],
        groupId: rootGetters[`${namespace}/getCollectionSetting`].groupId || null,
        pathFilter: rootGetters[`${namespace}/getCollectionSetting`].path_filter || [],
        ipListId: rootGetters[`${namespace}/getCollectionSetting`].ipListId || null,
        startDate: rootGetters[`${namespace}/getDateRange`].start,
        endDate: rootGetters[`${namespace}/getDateRange`].end,
        excludeBot: rootGetters[`${namespace}/getCollectionSetting`].excludeBot || false,
        removePreview: rootGetters[`${namespace}/getCollectionSetting`].removePreview !== false,
    };
}

module.exports = { defaultApiParams };